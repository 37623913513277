<template>
  <div>
    
  </div>
</template>

<script setup>
  window.location.href = 'https://abc.zhonghezhihui.com/api/Login/redirect'
</script>

<style lang="scss" scoped>

</style>